"use client"; // Error components must be Client Components

//import * as Sentry from "@sentry/nextjs";
import Button from "@/components/Elements/Button";
import Link from "next/link";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // useEffect(() => {
  //   Sentry.captureException(error);
  // }, [error]);

  const reloadPage = () => {
    reset();
    window.location.reload();
  };

  return (
    <div className="bg-blue text-white h-screen flex justify-center items-center">
      <div className="flex flex-col text-white text-center p-4 md:p-0">
        <h2 className="font-bold mb-2 text-4xl">Sidan kunde inte visas</h2>

        <p className="text-white">
          Vi kunde inte hitta sidan du letar efter. Sidan kanske har tagits bort
          eller flyttats.
        </p>

        <div className="w-full flex flex-row justify-center flex-wrap space-x-5 mt-5">
          <Button onClick={reloadPage} bgColor="yellow" textColor="black">
            Pröva igen
          </Button>
          <Link href="/" className="!no-underline">
            <Button borderColor="white">Gå till startsidan</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
